import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { showErrors } from "../../common/consts/helpers";
import Spinner from "../../SMEsustainability/components/Spinner";
import DataHubPage from './DataHub';
import RequestAccessPage from './RequestAccess';

const useQuery = () => new URLSearchParams(useLocation().search);

export default function Access() {
    const navigate = useNavigate();
    const query = useQuery();
    const [ token, setToken ] = useState(query.get('t'));

    const [ isLoading, setLoading ] = useState(true);
    const [ isAccessible, setAccessibility ] = useState(false);
    const [dataGridRows, setRows] = useState([]);

    const url = process.env.REACT_APP_EDH_LAMBDA;
    const checkEmailAPI = (email = '') => {
        setLoading(true);
        return fetch(url + '?' + new URLSearchParams({ email: email }), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            // console.log(response)
            return response.json();
        }).then(data => {
            // console.log(data)
            setAccessibility(data?.success)
            if (data?.success) setToken(data?.token);
            setLoading(false)
            return data;
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    }

    const handleFetch = (token) => {
        setLoading(true);
        fetch(url + 'datahub?' + new URLSearchParams({ t: token }), 
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(
            function (response) {
                return response.json();
            }
        ).then(
            function(data) {
                console.log(data)
                setAccessibility(data?.success)
                if (data?.success) setRows(data?.results);
                else navigate(process.env.REACT_APP_EDH_ROOT);
                setLoading(false);
            }
        ).catch(
            function(error) {
                showErrors(error);
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        setAccessibility(false);
        setLoading(false);
    }, [])

    useEffect(() => {
        if (token) handleFetch(token);
    }, [token])

    if (isLoading) return (
        <Stack className="centered-container" sx={{ minHeight: '60vh' }}>
            <Spinner width={'100px'} />
        </Stack>
    );
    else {
        if (isAccessible) return <DataHubPage dataGridRows={dataGridRows} isLoading={isLoading} />
        else return <RequestAccessPage checkEmailAPI={checkEmailAPI} isLoading={isLoading} />
    }
}