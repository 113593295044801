import { LinkedIn, LocationOnRounded, Mail, NearMeRounded } from '@mui/icons-material';
import {
    Box,
    Unstable_Grid2 as Grid,
    Typography
} from '@mui/material';
import CustomGridContent from './components/CustomGridContent';
import { sgfintech_apps } from './consts/apps';

export default function PageFooter() {
    const visibleApps = sgfintech_apps.filter(app => (!app.inDevelopment) || (app.inDevelopment && process.env.REACT_APP_BRANCH !== 'main')).slice(0, -1); ;
    return (
        <Box className="footer hide-on-print" sx={{ bottom: 0, maxWidth: '100vw' }}>
            <Box className="footer-top">
                <CustomGridContent>
                    <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    <LocationOnRounded sx={{ color: '#ef7c00' }} />
                    <a href='https://sgfin.nus.edu.sg/' target='_blank' rel='noreferrer' className='footer-weblink'>
                    <Typography variant='subtitle1'>
                        SGFIN Homepage
                    </Typography>
                    </a>
                    </Box>
                </CustomGridContent>
            </Box>
            <Box className="footer-bottom">
                <CustomGridContent>
                    <>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={4}>
                            <a href='/' className='footer-weblink'>
                                <Typography variant="subtitle1" color='white' >
                                    SGFIN Tools & Resources - {new Date().getFullYear()}
                                </Typography> 
                            </a>
                            {
                                visibleApps.map((app, app_key) => {
                                    return (
                                        <a key={app_key} href={'/#' + app.path} className='footer-weblink'>
                                            <Typography variant="subtitle1" >
                                                {app.title}
                                            </Typography> 
                                        </a>
                                    )
                                })
                            }
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Typography variant="subtitle1" color='white'>
                                Sustainable and Green Finance Institute
                            </Typography>   

                            <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                <NearMeRounded />
                                <Typography variant='subtitle1' display='inline' marginLeft={1} >
                                National University of Singapore <br />
                                Innovation 4.0<br />
                                3 Research Link #02-02<br />
                                Singapore 117602<br />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Typography variant="subtitle1" color='white'>
                                Get in Touch
                            </Typography>   

                            <a href='mailto:sgfin@nus.edu.sg' className='footer-weblink'>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                <Mail />
                                <Typography variant='subtitle1' display='inline' marginLeft={1} >
                                sgfin@nus.edu.sg
                                </Typography>
                            </Box>
                            </a>

                            <a href='https://www.linkedin.com/company/sustainable-and-green-finance-institute' className='footer-weblink'>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                                <LinkedIn />
                                <Typography variant='subtitle1' display='inline' marginLeft={1} >
                                Sustainable and Green Finance Institute
                                </Typography>
                            </Box>
                            </a>
                        </Grid>
                    </Grid>
                    </>
                </CustomGridContent>
            </Box>
            <Box className="footer-extension">
                <div className="ast-small-footer-wrap">
                    <div className="ast-small-footer-section ast-small-footer-section-1">
                        © <a href="https://nus.edu.sg" className='footer-weblink' target="_blank" rel="noopener noreferrer">National University of Singapore</a>. All Rights Reserved.
                    </div>
                    {/* <div className="ast-small-footer-section ast-small-footer-section-2">
                        <div className="nus-footer-bar">
                                <a className='footer-weblink' href="http://www.nus.edu.sg/legal-information-notices" target="_blank" rel="noreferrer">Legal</a>
                                &#x2022;
                                <a className='footer-weblink' href="http://www.nus.edu.sg/identity/" target="_blank" rel="noreferrer">Branding Guidelines</a>
                                &#x2022;
                                <a className='footer-weblink' href="http://www.nus.edu.sg/contact" target="_blank" rel="noreferrer">Contact Us</a>
                        </div>
                    </div> */}
                </div>
            </Box>
        </Box>

    )
}