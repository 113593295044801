import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Spinner from "../../common/components/Spinner";
import CalculatorPage from "./CalculatorPage";
import RequestAccessPage from './RequestAccess';

export default function Access() {
    const [ isLoading, setLoading ] = useState(true);
    const [ isAccessible, setAccessibility ] = useState(false);

    const url = process.env.REACT_APP_CCP_USER_LAMBDA;
    const checkUserAPI = (email = null) => {
        var fetch_url = url + (email ? '?' + new URLSearchParams({ email: email }) : '');
        return fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            // console.log(response)
            return response.json();
        }).then(data => {
            // console.log(data)
            setAccessibility(data?.success || false)
            return data;
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        }).finally(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        setLoading(true);
        setAccessibility(false);
        checkUserAPI();
    }, [])

    if (isLoading) {
        return (
        <Stack className="centered-container" sx={{ minHeight: '60vh' }}>
            <Spinner width={'100px'} />
        </Stack>
        )   
    } else {
        if (isAccessible) return <CalculatorPage />
        else return <RequestAccessPage checkUserAPI={checkUserAPI} isLoading={isLoading} setAccessibility={setAccessibility} />
    }
}