import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomAccordion = styled((props) => (
	<Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	'&:not(:last-child)': {
	borderBottom: 0,
	},
	'&:before': {
	display: 'none',
	},
}));

export const CustomAccordionSummary = styled((props) => (
    <AccordionSummary
        {...props}
        />
    ))(({ theme }) => ({
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
        margin: 0,
        },
}));
  
export const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));