import { Box, Container, Unstable_Grid2 as Grid, Typography } from "@mui/material";
import pic from '../images/cover.png';

export default function PlaceholderPage() {
    return (
        <Container maxWidth={'lg'} >
            <Grid container spacing={2} sx={{ my: 4 }}>
                <Grid xs={12} sm={3} className="centered-container">
                    <Box sx={{ boxShadow: 8 }}>
                    <img src={pic} alt="SGFIN Whitepaper 2024-01" style={{ width: '100%' }} />
                    </Box>
                </Grid>
                <Grid xs={12} sm={9} className="centered-container" sx={{ p: 6 }}>
                    <Box>
                        <Typography variant="subtitle2">
                            Upcoming Whitepaper:
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            Improving the Integrity of Sustainability Data: Reviewing Environmental Coverage of Sustainability Data Providers
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="subtitle2">
                            Upcoming Resource:
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            Environmental Data Hub: South-East Asia
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="body1" gutterBottom>
                            Thank you for your interest in our upcoming whitepaper, which will be released at the end of September. You can visit <a href="https://sgfin.nus.edu.sg/publications/whitepapers/" target="_blank">this link</a> to view it once it is available, or to explore other SGFIN whitepapers.
                        </Typography>
                        <Typography variant="body1">
                            In conjunction with the whitepaper, SGFIN will launch the Environmental Data Hub (on this page), offering insights into Southeast Asia's corporate sustainability reporting landscape and relevant sustainability data to facilitate investment and policy decisions.
                        </Typography>
                    </Box>
                    </Grid>
            </Grid>
        </Container>
    )
}