import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
    Box
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from './NavAccordion';
import NavItem from './NavItem';
  
export default function MobileNav({ navs, onClickAction }) {
	const navigate = useNavigate();

	const [expanded, setExpanded] = useState('');

	const handleChange = (panel) => (event, newExpanded) => {
	  setExpanded(newExpanded ? panel : false);
	};
    
    const handleClick = (item) => (event) => {
        if (!item.children) {
            onClickAction();
            navigate(item.path);
        }
    }

    return (
        <Box sx={{ display: { xs: 'block', md: 'none' }}} >
            {navs?.filter(item => item.title).map((item, index) => (
                <CustomAccordion expanded={expanded === item.title} onChange={handleChange(item.title)} key={index} >
                    <CustomAccordionSummary 
                        expandIcon={item.children?.length > 0 && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
                        aria-controls={"mobile-nav-content-"+index} id={"mobile-nav-"+index}
                    >
                        <NavItem 
                            id={item.title + '-button-mobile'}
                            item={item} forMobile={true}
                            onClickAction={handleClick(item)}
                        />
                    </CustomAccordionSummary>
                    {item.children &&
                        <CustomAccordionDetails>
                            { item.children.map((child, index) => (
                                <Box sx={{ my: 1 }} key={index}>
                                    <NavItem 
                                        item={child} forMobile={true}
                                        onClickAction={handleClick(child)}
                                    />
                                </Box>
                            ))}
                        </CustomAccordionDetails>
                    }
                </CustomAccordion>
            ))}
        </Box>
    )
}