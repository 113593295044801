import {
    Box,
    Unstable_Grid2 as Grid,
    Popover,
    Typography
} from '@mui/material';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavItem from './NavItem';

export default function DesktopNavItem({ item }) {
	const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

	const handleOpenDropdown = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseDropdown = () => {
		setAnchorEl(null);
	};

    const handleClick = (event, item) => {
        handleCloseDropdown();
        if (item.children) handleOpenDropdown(event);
        else navigate(item.path);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const includeFeatureContent = item?.feature;

    return (
        <Box sx={{ mx: 2 }}>
            { item.title && 
                <NavItem 
                    id={item.title + '-button-desktop'}
                    item={item} forMobile={false}
                    onClickAction={(e) => {handleClick(e, item)}}
                />
            }
            {/* Dropdown for nav items with children */}
            { item.title && item.children && 
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseDropdown}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    slotProps={{
                        paper : {
                            style: { 
                                minWidth: '10%',
                                maxWidth: '30%',
                                margin: '3vh 0',
                                borderRadius: '16px',
                            },
                        }
                    }}
                    disableScrollLock
                    elevation={4}
                >
                    <Grid container sx={{ padding: 2 }}>
                        {/* Custom div for featured content */}
                        {
                            includeFeatureContent &&
                            <>
                            <Grid sm={0} md={12}>
                                <Typography variant='subtitle1' sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>FEATURED</Typography>
                            </Grid>
                            <Grid sm={0} md={8} lg={6} >
                                <Box sx={{  my: 2, borderRadius: '8px', width: '100%', aspectRatio: 1, background: `var(--bg-light) url(${item.feature?.image}) no-repeat center/cover` }} />
                                <Typography variant='caption' sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, mb: 2 }}>
                                    { item.feature?.desc }
                                </Typography>
                            </Grid>
                            </>
                        }

                        {/* Nav options */}
                        <Grid sm={12} md={includeFeatureContent ? 4 : 12} lg={includeFeatureContent ? 6 : 12}>
                            {item.children && item.children.map((child, index) => (
                                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}  key={index}>
                                    <NavItem 
                                        item={child} forMobile={false}
                                        onClickAction={(e) => {handleClick(e, child)}}
                                    />
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Popover>
            }
        </Box>
    )
}