import { getGridSingleSelectOperators } from "@mui/x-data-grid";

const DECIMAL_RULE = {maximumFractionDigits: 2, minimumFractionDigits: 2};

const ssFilterOperators = getGridSingleSelectOperators()
.filter((operator) => operator.value === "is")
.map((operator) => {
    const newOperator = { ...operator }
    const newGetApplyFilterFn = (filterItem, column) => {
    return (params) => {
        let isOk = true
        filterItem?.value?.forEach((fv) => {
        isOk = isOk && params.value.includes(fv)
        })
        return isOk
    }
    }
    newOperator.getApplyFilterFn = newGetApplyFilterFn
    return newOperator
})

const formatCols = (cols) => {
    cols.forEach((col, k) => {
        if (col.field.substring(0,4)==="data") {
            cols[k] = {
                ...cols[k],
                filterOperators: ssFilterOperators,
                renderCell: (params) => (
                    <div title={params.value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {
                        (params.value === '-' || params.value === null) ? ''
                        : params.value < 0 ?
                        `$ (${Math.abs(params.value).toLocaleString('en-US', DECIMAL_RULE)})`
                        : params.value >= 0 ?
                        `$ ${params.value.toLocaleString('en-US', DECIMAL_RULE)}`
                        :
                        params.value
                    }
                    </div>
                ),
            }
        }

        //// Colours different data columns
        // if (["L1_aspect", "L2_pillar", "L3_indicator", "L4_subindicator", "L5_impactValue"].includes(col.field)) {
        //     cols[k] = { ...cols[k], 
        //         cellClassName: (params) => {
        //             let aspect = params.row.L1_aspect;
        //             if (aspect === null) return '';
        //             return clsx(col_classes[col.field], {
        //                 econ: aspect === "Econ.",
        //                 gov: aspect === "Gov.",
        //                 env: aspect === "Env.",
        //                 soc: aspect === "Soc.",
        //             });
        //         },
        //     }
        // }
    });
    return cols;
};

export const dataGridColumns = formatCols([
    { 
        field: 'company_name', 
        headerName: 'Company Name',
        headerClassName: 'col-grey',
        minWidth: 250
    },
    { 
        field: 'industry_name',
        headerName: 'Industry',
        headerClassName: 'col-grey',
        minWidth: 150
    },
    { 
        field: 'sector_name',
        headerName: 'Sector',
        headerClassName: 'col-grey',
        minWidth: 150
    },
    { 
        field: 'fiscal_year',
        headerName: 'Fiscal Year',
        headerClassName: 'col-grey',
        minWidth: 150
    },
    {
        field: 'data_revenue',
        headerName: 'Revenue - US$',
        headerClassName: 'col-yellow',
        minWidth: 250,
    },
    {
        field: 'data_ghg_scope1',
        headerName: 'GHG Scope 1 (tCO₂e)' ,
        headerClassName: 'col-green',
        minWidth: 250,
    },
    {
        field: 'data_ghg_scope2_location',
        headerName: 'GHG Scope 2 Location-Based (tCO₂e)' ,
        headerClassName: 'col-green',
        minWidth: 250,
    },
    {
        field: 'data_ghg_scope2_market',
        headerName: 'GHG Scope 2 Market-Based (tCO₂e)' ,
        headerClassName: 'col-green',
        minWidth: 250,
    },
    {
        field: 'data_internal_carbon_pricing',
        headerName: 'Internal Carbon Pricing (Y/N)' ,
        headerClassName: 'col-green',
        minWidth: 250,
    },
    {
        field: 'data_internal_carbon_pricing_pertonne',
        headerName: 'Internal Carbon Price per Tonne' ,
        headerClassName: 'col-green',
        minWidth: 250,
    },
    {
        field: 'data_internal_carbon_price_currency',
        headerName: 'Internal Carbon Price Currency' ,
        headerClassName: 'col-green',
        minWidth: 250,
    },
    {
        field: 'data_total_energy_use',
        headerName: 'Energy Use, Total (GJ)' ,
        headerClassName: 'col-red',
        minWidth: 250,
    },
    {
        field: 'data_total_electricity_use',
        headerName: 'Electricity Use, Total (GJ)' ,
        headerClassName: 'col-red',
        minWidth: 250,
    },
    {
        field: 'data_total_waste',
        headerName: 'Waste, Total (tonnes)' ,
        headerClassName: 'col-cream',
        minWidth: 250,
    },
    {
        field: 'data_total_water_use',
        headerName: 'Water Use, Total (m³)' ,
        headerClassName: 'col-blue',
        minWidth: 250,
    },
    {
        field: 'data_total_water_withdrawn',
        headerName: 'Water Withdrawal, Total (m³)' ,
        headerClassName: 'col-blue',
        minWidth: 250,
    },
]);
