import {
    Navigate,
    useRoutes
} from "react-router-dom";
import './App.css';
// import { NAV_CONFIG_APP as SMESust_NAVCONFIG } from "./SMEsustainability/components/nav/NavConfig";
import { NAV_CONFIG_APP as CarbonCalc_NAVCONFIG } from "./carbonCalc/components/nav/NavConfig";
import AppLayout from "./carbonCalc/layouts/AppLayout";
import { NAV_CONFIG_APP as CarbonPricing_NAVCONFIG } from "./carbonPricing/components/nav/NavConfig";
import MainLandingPage from "./common/MainLandingPage";
import NotFoundPage from "./common/NotFoundPage";
import { NAV_CONFIG_APP as EnvDataHub_NAVCONFIG } from "./envDataHub/components/nav/NavConfig";
// import ProtectedRoute from "./common/ProtectedRoute";
// import SignIn from "./common/auth/LimitedAccess_SignIn";
import Base from "./common/layouts/Base";
// import { NAV_CONFIG_APP2 } from "./newApp/components/nav/NavConfig";
// import { NAV_CONFIG_APP as PlotReports_NAVCONFIG } from "./plotReports/components/nav/NavConfig";

export default function Router() {
    return useRoutes([
    {
        path: "/",
        element: <MainLandingPage />,
    },
    // {
    //     path: process.env.REACT_APP_CC_ROOT_HOME + "/*",
    //     element: <MaintenancePage appName={"MICE Carbon Calculator"} />,
    // },
    {
        path: CarbonCalc_NAVCONFIG.rootPath,
        element:
            <AppLayout />
        ,
        children: CarbonCalc_NAVCONFIG.routes
    },
    {
        path: "/corporate_carbon_pricing",  // OLD
        element: <Navigate to={CarbonPricing_NAVCONFIG.rootPath} />,
    },
    {
        path: CarbonPricing_NAVCONFIG.rootPath,
        element:
        // <ProtectedRoute authVariableName='isAuth_CCP' signInURL='/signin-ccp'>
            <Base nav_config={CarbonPricing_NAVCONFIG.routes} title={"Corporate Emission Pricing"} />
        // </ProtectedRoute>
        ,
        children: CarbonPricing_NAVCONFIG.routes
    },
    // {
    //     path: '/signin-ccp',
    //     element: <SignIn 
    //         pageName='Corporate Carbon Pricing' 
    //         authVariableName='isAuth_CCP' 
    //         password={process.env.REACT_APP_CCP_PW} 
    //         pageRedirect={CarbonPricing_NAVCONFIG.rootPath}
    //     />,
    // },
    // {
    //     path: SMESust_NAVCONFIG.rootPath,
    //     element: <Base nav_config={SMESust_NAVCONFIG.routes} />,
    //     children: SMESust_NAVCONFIG.routes
    // },
    // {
    //     path: NAV_CONFIG_APP2.rootPath,
    //     element: <Base nav_config={NAV_CONFIG_APP2.routes} />,
    //     children: NAV_CONFIG_APP2.routes
    // },
    {
        path: "*",
        element: <NotFoundPage />,
    },
    {
        path: EnvDataHub_NAVCONFIG.rootPath,
        element: <Base nav_config={EnvDataHub_NAVCONFIG.routes} />,
        children: EnvDataHub_NAVCONFIG.routes
    },
    ]);
}