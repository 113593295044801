import Access from "../../pages/Access";
import PlaceholderPage from "../../pages/Placeholder";

const pathRoot = process.env.REACT_APP_EDH_ROOT;
export const NAV_CONFIG_APP = {
    rootPath: pathRoot,
    routes:
    [
        {
            title: 'Data Hub',
            path: pathRoot + '/notlive',
            element: <Access />,
            isHidden: true, // TEMP - TODO remove on go-live
        },
        {
            title: 'Data Hub',
            path: pathRoot,
            element: <PlaceholderPage />,
        },
    ]
}