import { Box } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { dataGridColumns } from "../consts/dataConsts";

const ODD_OPACITY = 0.2;
// TODO: remove when actual data can be ported in
function generateRandomString(length, isNum=false) {
    // Define the character pool for the random string
    const characterPool = isNum ? "123456789" : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = '';
    for (let i = 0; i < length; i++) {
      // Generate a random index within the character pool
      const randomIndex = Math.floor(Math.random() * characterPool.length);
      // Append the character at the random index to the result string
      result += characterPool.charAt(randomIndex);
    }
    return result;
}
const rows = Array.from({ length: 100 }, (_, i) => ({
    id: i,
    company_name: 'Company ' + generateRandomString(3),
    fiscal_year: 2022 - (i % 4),
    indicator1: generateRandomString(4, true),
    indicator2: generateRandomString(4, true),
    indicator: '...',
    indicatorN: generateRandomString(4, true),
}));

export default function DataHubPage({ dataGridRows=[], isLoading=true }) {
    return (
        <Box sx={{ height: "90vh", p: 3, pt: 0 }}>
            <StripedDataGrid 
                rows={dataGridRows} 
                columns={dataGridColumns} 
                pageSizeOptions={[100]}
                // disableColumnSorting
                rowHeight={25}
                loading={isLoading}
                slotProps={{
                    loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'skeleton',
                    },
                }}
                getRowClassName={(params) =>{
                    var groupIndex = Math.floor(params.indexRelativeToCurrentPage / 4);
                    return groupIndex % 2 === 0 ? 'even' : 'odd';
                }}
            />
        </Box>
    )
}

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
            backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY +
                theme.palette.action.selectedOpacity +
                theme.palette.action.hoverOpacity,
            ),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
            },
            },
        },
    },
    [`& .${gridClasses.columnHeaderTitle}`]: {
        fontWeight: 'bold',
    },
    '& .col-grey': {
        backgroundColor: '#B8C2AD',
    },
    '& .col-green': {
        backgroundColor: '#99FF99',
    },
    '& .col-red': {
        backgroundColor: '#E6B8B7',
    },
    '& .col-cream': {
        backgroundColor: '#FFFFCC',
    },
    '& .col-blue': {
        backgroundColor: '#B7DEE8',
    },
    '& .col-yellow': {
        backgroundColor: '#FFFF89',
    },
}));