import {
    Box,
    Typography
} from '@mui/material';
import AppCardLinks from './AppCardLinks';
import PageFooter from './PageFooter';
import CustomGridContent from './components/CustomGridContent';
import logoImg from './images/NUS_SGFIN_logo.png';

// const Subheader = styled(Typography) ({
//     fontSize: '1.2em',
//     fontWeight: '700',
//     marginTop: '3em'
// })

export default function MainLandingPage() {
    return (
        <>
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '2em', minHeight: '60vh' }}>
                <img src={logoImg} style={{ maxHeight: '70px', maxWidth: '80vw', marginBottom: '2em' }} alt="NUS SGFIN logo" />
                {/* <Typography variant='h4' sx={{ color: "var(--pri-blue)", textAlign: 'center', fontWeight: '100 !important' }}>
                    Sustainable and Green Finance Institute
                </Typography> */}
                <Typography variant='h4' sx={{ color: "var(--pri-blue)", marginBottom: 3 }}>
                    Tools & Resources
                </Typography>
                <CustomGridContent>
                    {
                        // TODO: make this dynamic when more tools come into the picture
                    }
                    <AppCardLinks />
                </CustomGridContent>
            </Box>

            {/* <Box className="light-background" sx={{ display: 'flex', padding: '6em 2em' }}>
                <CustomGridContent>
                <Box id="mainlanding-intro" sx={{ mt: 2 }}>
                    <Subheader variant='h5'>Welcome</Subheader>
                    <Typography>
                        In this toolkit, you will find various projects and resources developed by SGFIN researchers. Click the links to each resource to explore more. We can include more descriptions here!
                    </Typography>
                </Box>
                </CustomGridContent>
            </Box> */}
        </Box>
        <PageFooter />
    </>
    )
}