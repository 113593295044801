import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Unstable_Grid2 as Grid, Stack, TextField, Typography } from "@mui/material";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Container from "@mui/material/Container";
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from "react";
import EllipsisLoader from '../../carbonCalc/components/loaders/EllipsisLoader';
import { isValidEmail } from '../../common/consts/helpers';
import { countryOptions, dataUseOptions, userTypeOptions } from '../consts/formConsts';

const boxStyle = {
    border: '1px solid #fff',
    backgroundColor: '#ffffffdd',
    borderRadius: '24px',
    p: 3,
    my: 4,
    // minHeight: '150px',
    backdropFilter: 'blur(3px)',
    transition: 'box-shadow 0.5s, transform 0.5s',
};

export default function RequestAccessPage({ checkEmailAPI, isLoading }) {
    // Display control
    const [isShowForm, setShowForm] = useState(true);
    const [isLoadingRegistration, setLoading] = useState(false);

    //  Inputs
    const [isReadyToSubmit, setReadyToSubmit] = useState(false);
    const [inputEmail, setInputEmail] = useState('');
    const [inputFirstName, setInputFirstName] = useState('');
    const [inputLastName, setInputLastName] = useState('');
    const [inputCompany, setInputCompany] = useState('');
    const [inputCountry, setInputCountry] = useState('');
    const [inputUserType, setInputUserType] = useState('');
    const [inputDataUse, setInputDataUse] = useState('');
    const [inputAck, setInputAck] = useState(false);
    const [inputConsent, setInputConsent] = useState(false);
    const requiredFields = [inputEmail, inputFirstName, inputLastName, inputCompany, inputCountry, inputUserType, inputDataUse, inputAck, inputConsent];

    const handleCheckbox = (setterFunc) => {
        setterFunc(lastBool => !lastBool);
    }

    const handleEmailAccessCheck = async() => {
        if (inputEmail) {
            const { success, message } = await checkEmailAPI(inputEmail);
            setAlertResponse(success ? 'success' : 'warning', message);
        } else {
            setAlertResponse('warning', "Please provide the email address that you used to request access with.")
        }
    }

    const [isAlertOpen, setOpen] = useState(false);
    const [apiResponse, setResponse] = useState({});

    const setAlertResponse = (severity, message) => {
        setResponse({ severity: severity, message: message });
        setOpen(Boolean(message));
        setTimeout(() => {
            setOpen(false);
        }, 20000);
    }
    
    const url = process.env.REACT_APP_EDH_LAMBDA;
    const handleSubmit = () => {
        setLoading(true);
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                inputEmail: inputEmail,
                inputFirstName: inputFirstName,
                inputLastName: inputLastName,
                inputCompany: inputCompany,
                inputCountry: inputCountry,
                inputUserType: inputUserType,
                inputDataUse: inputDataUse,
                inputAck: inputAck,
                inputConsent: inputConsent,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(response => {
            console.log(response)
            return response.json();
        }).then(data => {
            console.log(data);
            setLoading(false);
            setAlertResponse(data?.success ? 'success' : 'warning', data?.message);
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    };

    useEffect(() => {
        var allFilled = requiredFields.every(Boolean);
        setReadyToSubmit(allFilled)
    }, [...requiredFields])

    return (
        <Box
            sx={{ 
                display: 'flex', 
                minHeight: '80vh', 
                justifyContent: 'center',
                flexDirection: 'column',
                background: "linear-gradient(45deg, #D3FCE4, #F6F3B4);",
                // background: `var(--bg-light) url(${gradient}) no-repeat center/cover fixed`, width: '100%', minHeight: '90vh'
            }}
        >
            <Container maxWidth='xl'>
                <Box sx={boxStyle}>
                    <Typography variant='h4' gutterBottom>Request Access to SGFIN Environmental Data Hub</Typography>
                    <Collapse in={isShowForm} >
                        <Box sx={{ pb: 3, mb: 2, borderBottom: '2px solid grey' }}>
                            <Typography variant='body2' gutterBottom>Please fill in the form below to request for access to our data hub. A link will be sent to your email granting you access to our data hub.</Typography>
                            <Typography variant='body2' sx={{ mb: 2 }}>If you have any questions or encounter any issues during the process, please feel free to contact us at <a href='mailto:sgfin@nus.edu.sg' >sgfin@nus.edu.sg</a>.</Typography>
                            <FormControl>
                                <Grid container spacing={2}>
                                    <Grid xs={12} sm={12}>
                                        <AccessFormTextField
                                            idname='email_field'
                                            label='Business Email Address'
                                            value={inputEmail}
                                            valueSetter={setInputEmail}
                                            error={!isValidEmail(inputEmail)}
                                            helperText={!isValidEmail(inputEmail) ? "Please provide a valid email" : ""}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormTextField
                                            idname='name_field'
                                            label='First Name'
                                            value={inputFirstName}
                                            valueSetter={setInputFirstName}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormTextField
                                            idname='name_field'
                                            label='Last Name'
                                            value={inputLastName}
                                            valueSetter={setInputLastName}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormTextField
                                            idname='company_field'
                                            label='Company/Organisation'
                                            value={inputCompany}
                                            valueSetter={setInputCompany}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormSelect
                                            idname='country_field'
                                            label='Country'
                                            value={inputCountry}
                                            valueSetter={setInputCountry}
                                            options={countryOptions}
                                            dropdownOnly={true}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormSelect
                                            idname='usertype_field'
                                            label='Role'
                                            value={inputUserType}
                                            valueSetter={setInputUserType}
                                            options={userTypeOptions}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <AccessFormSelect
                                            idname='datause_field'
                                            label='Data Use Case'
                                            value={inputDataUse}
                                            valueSetter={setInputDataUse}
                                            options={dataUseOptions}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <FormControlLabel 
                                            value={'Acknowledged'} label={"I acknowledge that any use of the data provided in this data hub will be properly attributed to SGFIN."}
                                            control={
                                                <Checkbox 
                                                    checked={inputAck} 
                                                    onChange={() => handleCheckbox(setInputAck)}  
                                                    name={'Acknowledged'} 
                                                    sx={{ padding: '8px' }}
                                                    required
                                                />
                                            } 
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <FormControlLabel 
                                            value={'Consent'} label={"I consent to receiving promotional materials and updates regarding the data hub via email from SGFIN."}
                                            control={
                                                <Checkbox 
                                                    checked={inputConsent} 
                                                    onChange={() => handleCheckbox(setInputConsent)}  
                                                    name={'Consent'} 
                                                    sx={{ padding: '8px' }}
                                                    required
                                                />
                                            } 
                                        />
                                    </Grid>
                                </Grid>
                                <Button className={'pill_button submit_button' + (isReadyToSubmit ? '' : ' disabled_button')} type='submit' onClick={handleSubmit} sx={{ mt: 2 }} disabled={!isReadyToSubmit} >
                                    {
                                        isLoadingRegistration ?
                                        <>
                                        &nbsp;<EllipsisLoader />&nbsp;
                                        </>
                                        :
                                        'Submit Request'
                                    }
                                </Button>
                            </FormControl>
                        </Box>
                    </Collapse>
                    <Stack sx={{ display: 'flex', gap: 2 }}>
                        <Typography variant='body2'>If you have already requested for access previously, click the button below:</Typography>
                        {   !isShowForm &&
                            <AccessFormTextField
                                idname='email_field'
                                label='Business Email Address'
                                value={inputEmail}
                                valueSetter={setInputEmail}
                            />
                        }
                        <Button className='pill_button' onClick={() => isShowForm ? setShowForm(false) : handleEmailAccessCheck() }>
                            { 
                            isLoading ?
                            <>
                            &nbsp;<EllipsisLoader />&nbsp;
                            </>
                            :
                            isShowForm ? 'Proceed to Data' : 'Continue'
                            }
                        </Button>
                        {   !isShowForm &&
                            <Button className='pill_button secondary_button' onClick={() => setShowForm(true)}>Back to Request Form</Button>
                        }
                    </Stack>
                </Box>
                <Collapse in={isAlertOpen}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 4, borderRadius: '24px' }}
                    severity={apiResponse.severity}
                    >
                    {apiResponse.message}
                    </Alert>
                </Collapse>
            </Container>
        </Box>
    )
}

const AccessFormTextField = ({ idname, value, label, valueSetter, ...props }) => {
    return (
        <FormControl fullWidth>
        <FormLabel htmlFor={idname} id={`${idname}-label`}>{label}</FormLabel>
        <TextField
            className={idname}
            name={idname} id={idname}
            onChange={e => valueSetter(e.target.value)}
            variant='outlined'
            value={value}
            type='text'
            size='small'
            required
            {...props}
        />
        </FormControl>
    )
}
const AccessFormSelect = ({ idname, value, label, valueSetter, options, dropdownOnly=false }) => {
    const placeholder = `Please select one from the dropdown${ !dropdownOnly ? ' or type your own answer' : '' }:`
    return (
        <FormControl fullWidth>
            <FormLabel htmlFor={idname} id={`${idname}-label`}>{label}</FormLabel>
            <Autocomplete
                freeSolo={!dropdownOnly}
                name={idname} id={idname}
                options={["", ...options]}
                value={value}
                onChange={(event, newValue) => {
                    valueSetter(newValue || '');
                }}
                inputValue={value}
                onInputChange={(event, newInputValue) => {
                    valueSetter(newInputValue || '');
                }}
                renderInput={(params) => 
                    <TextField 
                        {...params}
                        placeholder={placeholder}
                        aria-label={placeholder}
                        fullWidth
                    />
                }
            />
        </FormControl>
    )
}