import {
    Container
} from '@mui/material';

export default function CustomGridContent({ children }) {
    return (
        <Container maxWidth="xl">
            {children}
        </Container>
    )
}